<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
      <el-breadcrumb-item>菜单列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card shadow="never">
      <div class="change">

        <el-button type="primary" @click="addDialogVisible = true">新增</el-button>
        <el-button type="info" class="back" @click="back" v-show="showRentPrise">返回</el-button>
      </div>
      <!-- 用户信息框 -->
      <el-table :data="menuList" border stripe style="width: 100%"
        :header-cell-style="{ background: 'rgba(22, 119, 255, 0.0300)' }">
        <el-table-column type="index" align="center"> </el-table-column>
        <el-table-column label="名称" width="" prop="menuName" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="next(scope.row)">{{ scope.row.menuName }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="menuType" label="类型" width="" align="center">
          <template slot-scope="scope">{{ map[scope.row.menuType] }}</template>
        </el-table-column>
        <el-table-column prop="menuState" label="状态" width="" align="center">
          <template slot-scope="scope">{{ map1[scope.row.menuState] }}</template>
        </el-table-column>
        <el-table-column label="操作" width="" align="center">
          <template slot-scope="scope">
            <el-button type="warning" size="mini" icon="el-icon-edit" @click="edit(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" icon="el-icon-delete" @click="removeMenu(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 添加菜单对话框 -->
      <el-dialog title="新增菜单" :visible.sync="addDialogVisible" width="40%" @close="closeDialog">
        <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="80px">
          <el-form-item label="菜单名称" prop="menuName">
            <el-input v-model="addForm.menuName"></el-input>
          </el-form-item>
          <el-form-item label="菜单地址" prop="menuUrl">
            <el-input v-model="addForm.menuUrl"></el-input>
          </el-form-item>
          <el-form-item label="菜单状态" prop="menuState">
            <el-select placeholder="请选择状态" v-model="addForm.menuState">
              <el-option v-for="item in stateList" :key="item.id" :label="item.stateName" :value="item.stateName">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型" prop="menuType">
            <el-select placeholder="请选择类型" v-model="addForm.menuType">
              <el-option v-for="item in typeList" :key="item.id" :label="item.type" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addMenu">保 存</el-button>
          <el-button @click="addDialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>
      <!-- 编辑菜单对话框 -->
      <el-dialog title="编辑菜单" :visible.sync="editDialogVisible" width="40%" @close="editCloseDialog">
        <el-form ref="editFormRef" :model="editForm" :rules="editFormRules" label-width="80px">
          <el-form-item label="菜单名称" prop="menuName">
            <el-input v-model="editForm.menuName"></el-input>
          </el-form-item>
          <el-form-item label="菜单地址" prop="menuUrl">
            <el-input v-model="editForm.menuUrl"></el-input>
          </el-form-item>
          <el-form-item label="菜单状态" prop="menuState">
            <el-select placeholder="请选择状态" v-model="editForm.menuState">
              <el-option v-for="item in stateList" :key="item.id" :label="item.stateName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型" prop="menuType">
            <el-select placeholder="请选择类型" v-model="editForm.menuType">
              <el-option v-for="item in typeList" :key="item.id" :label="item.type" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="editMenu">保 存</el-button>
          <el-button @click="editDialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 控制添加菜单对话框的显示与隐藏
      addDialogVisible: false,
      // 控制添加菜单对话框的显示与隐藏
      editDialogVisible: false,
      // 获取菜单列表
      menuList: [],
      queryInfo: {
        menuId: 0,
        menuName: '',
      },
      // 添加菜单表单数据
      addForm: {
        id: '',
        menuName: '',
        menuState: '',
        menuType: '',
        menuUrl: '',
        parentId: '',
        arr: [],
      },
      // 添加表单的验证规则对象
      addFormRules: {
        menuName: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
        ],
        menuUrl: [
          { required: true, message: '请输入菜单地址', trigger: 'blur' },
        ],

        menuState: [
          { required: true, message: '请选择菜单状态', trigger: 'change' },
        ],
        menuType: [
          { required: true, message: '请选择类型', trigger: 'change' },
        ],
      },
      // 编辑表单的验证规则对象
      editFormRules: {
        menuName: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
        ],
        menuUrl: [
          { required: true, message: '请输入菜单地址', trigger: 'blur' },
        ],

        menuState: [
          { required: true, message: '请选择菜单状态', trigger: 'change' },
        ],
        menuType: [
          { required: true, message: '请选择类型', trigger: 'change' },
        ],
      },
      // 修改菜单表单数据
      editForm: {},
      total: 0,
      //   类型
      map: ['', '业务', '系统'],
      //   状态
      map1: ['隐藏', '显示'],
      stateList: [
        {
          id: 0,
          stateName: '隐藏',
        },
        {
          id: 1,
          stateName: '显示',
        },
      ],
      typeList: [
        {
          id: 1,
          type: '业务',
        },
        {
          id: 2,
          type: '系统',
        },
      ],
      showRentPrise: false,
    }
  },
  created() {
    this.getMenuList()
  },
  methods: {
    // 获取菜单列表
    getMenuList() {
      this.axios
        .get(`/api/site/menu/getMenuList?menuId=${this.queryInfo.menuId}`)
        .then((res) => {
          //   console.log(res)
          this.menuList = res.data.data
        })
    },

    next(row) {
      this.axios
        .get(`/api/site/menu/getMenuList?menuId=${row.id}`)
        .then((res) => {
          // console.log(res)
          this.menuList = res.data.data
          this.addForm.arr.unshift(row.id)
          this.showRentPrise = true
          // console.log(this.addForm.arr)
        })
    },
    // 监听添加菜单对话框关闭重置
    closeDialog() {
      this.$refs.addFormRef.resetFields()
    },
    // 点击添加新菜单
    addMenu() {
      this.$refs.addFormRef.validate((valid) => {
        // console.log(valid)
        if (!valid) return
        this.axios
          .post('/api/site/menu/add', {
            menuName: this.addForm.menuName,
            menuType: this.addForm.menuType,
            parentId: this.addForm.arr[0],
          })
          .then((res) => {
            // console.log(res)
            this.$message({
              message: '添加成功',
              type: 'success',
              duration: 1000,
            })
            // 重新获取用户列表
            this.getMenuList()
            // 点击确认关闭
            this.addDialogVisible = false
          })
      })
    },
    // 编辑菜单信息
    edit(row) {
      this.editDialogVisible = true
      //   console.log(id)
      this.axios
        .get(`/api/site/menu/getMenuInfo?menuId=${row.id}`)
        .then((res) => {
          //   console.log(res)
          this.editForm = res.data.data
          this.editForm.menuType = +res.data.data.menuType
        })
    },
    // 监听修改菜单对话框关闭重置
    editCloseDialog() {
      this.$refs.editFormRef.resetFields()
    },
    // 点击修改菜单
    editMenu() {
      this.$refs.editFormRef.validate((valid) => {
        if (!valid) return
        // console.log(valid)
        this.axios
          .post('/api/site/menu/editMenu', this.editForm)
          .then((res) => {
            //   console.log(res)
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 1000,
            })
            // 重新获取菜单列表
            this.getMenuList()
            // 点击确认关闭
            this.editDialogVisible = false
          })
      })
    },
    // 根据id删除对应菜单信息
    removeMenu(row) {
      this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.axios.get(`/api/site/menu/delMenu?menuId=${row.id}`)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.getMenuList()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    back() {
      this.$router.go()
      this.addForm.arr = []
    },
  },
  // 搜索数据列表跟随变化
  watch: {
    queryInfo: {
      handler: function (newVal) {
        console.log(newVal.queryInfo)
        this.getMenuList()
      },
      deep: true,
    },
  },
}
</script>
<style scoped lang="less">
.el-breadcrumb {
  margin-bottom: 20px;
}

.el-table {
  margin-top: 20px;
}

.el-input {
  margin-right: 8px;
  width: 240px;
}

.el-card {
  margin-bottom: 20px;
}

.change {
  margin: 20px 0;

  .back {
    margin-left: 20px;
  }
}
</style>
      
    
